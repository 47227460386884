/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const Turnos = () => {
  const { t } = useTranslation();
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    fetchUpcomingEventsData9();
  }, []);

  async function fetchUpcomingEventsData9() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTurnos",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [IsDeleting, setIsDeleting] = useState(false);

  const deleteTurnos = async (id_turno) => {
    if (!csrfToken) return;
    if (!token) return;

    if (id_turno == null || id_turno == undefined) return;

    if (IsDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/deleteTurno",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id: id_turno }),
        }
      );
      if (response.ok) showNiceMessage2(t("DELETE_SHIFT"));

      fetchUpcomingEventsData9();
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  function verificaHorario(horario: string) {
    // Cria um objeto Date para o horário atual
    let agora = new Date();

    // Configura o objeto Date com o horário recebido
    agora.setHours(parseInt(horario.substring(0, 2)));
    agora.setMinutes(parseInt(horario.substring(3, 5)));

    // Cria objetos Date para os limites 03:00 e 05:00
    let entrada = new Date(agora);
    entrada.setHours(3, 31, 0); // 03:31

    let saida = new Date(agora);
    saida.setHours(7, 0, 0); // 07:00

    // Compara os horários
    return agora < entrada || agora > saida;
  }
  const [IsAddTurno, setIsAddTurno] = useState(false);

  const addTurno = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddTurno) return;
    setIsAddTurno(true);

    let entrada = horaI?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let saida = horaF?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (
      entrada == "" ||
      entrada == undefined ||
      saida == "" ||
      saida == undefined
    ) {
      setIsAddTurno(false);
      setErrorMessage(t("SHIFT_ERR1"));
      return;
    }

    if (!verificaHorario(saida)) {
      setIsAddTurno(false);
      setErrorMessage(t("SHIFT_ERR4"));
      return;
    }

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/addTurno", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
          "x-xsrf-token": csrfToken, // Add CSRF token in the request header
        },
        credentials: "include",
        body: JSON.stringify({
          entrada,
          saida,
        }),
      });

      if (response.ok) {
        fetchUpcomingEventsData9();
        hideEditDiasCompletos();
        showNiceMessage(t("SUCCESS_SHIFT"));
        return;
      } else {
        setErrorMessage(t("SHIFT_ERR2"));
      }
    } catch (error) {
      setErrorMessage(t("SHIFT_ERR2"));
      console.error("An error occurred:", error);
    } finally {
      setIsAddTurno(false);
    }
  };

  const [horaI, setHoraI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [horaF, setHoraF] = useState<Date | undefined>(undefined); // Inicial  const [horaF, setHoraF] = useState(null);

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setHoraI(undefined);
    setErrorMessage("");
    setHoraF(undefined);
    document.body.classList.add("no-scroll");
  };

  const openDiasCompletos = () => {
    handleVacationButtonClick();

    clean();

    setHeader(t("SHIFT"));
    setIcon("pi-calendar-clock");
    setEditDiasCompletosDialog(true);
  };

  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label={t("SHIFT")}
            icon="pi pi-plus"
            rounded
            className=" mr-2"
            onClick={openDiasCompletos}
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const eliminarFalta = (rowData: Falta) => {
    if (true) {
      return (
        <>
          <Button
            icon="pi pi-trash"
            rounded
            onClick={() => confirm1(rowData)}
            className="pencil"
            size="small"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const confirm1 = (rowData) => {
    setSelectedRow(rowData.id);

    confirmDialog({
      message: t("DELETE_CONFIRMATION"),
    });
  };

  const editDiasCompletosFooter = (
    <>
      <Button
        label={t("ADD")}
        icon="pi pi-check"
        text
        onClick={addTurno}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id === selectedRow ? "highlight" : "";
  };

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage={t("NO_SHIFT")}
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
          >
            <Column
              body={eliminarFalta}
              style={{
                minWidth: "4rem",
              }}
            ></Column>
            <Column
              field="entrada"
              header={t("SHIFT1")}
              style={{
                minWidth: "5rem",
              }}
            ></Column>
            <Column
              field="saida"
              header={t("SHIFT2")}
              style={{ minWidth: "5rem" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
            dismissableMask={true} // Allow clicking outside to close the dialog
          >
            <div className="field">
              <label htmlFor="horaentrada" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Calendar
                value={horaI}
                onChange={(e) => setHoraI(e.value)}
                timeOnly
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Calendar
                value={horaF}
                onChange={(e) => setHoraF(e.value)}
                timeOnly
              />
            </div>

            <p>
              <span id="erro2" style={{ color: "brown" }}>
                {t("SHIFT_MESSAGE1")}
              </span>
            </p>
            <p>
              <span id="erro2" style={{ color: "brown" }}>
                {t("SHIFT_MESSAGE2")}
              </span>
            </p>
            <p>
              <span id="erro2" style={{ color: "brown" }}>
                {t("SHIFT_MESSAGE3")}
              </span>
            </p>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label={t("CANCEL")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      deleteTurnos(selectedRow);
                      hide(event);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default Turnos;
